import React, { useState } from "react";
import { Flight2024list } from "../utils/Flight2024List";
import Smalllogo from "../../Images/smallLogo.png";
import { whytm } from "../utils/AboutInfo";
import Multiplepoints from "../component/BlogsComponents/Multiplepoints";
import TestimonialSection from "../component/TestimonialSection";
import ImagesliderContainer from "../component/ImagesliderContainer";
import UniversityCollaboration from "../component/UniversityCollaboration";

const Flightinsights = () => {
  const [activeItem, setActiveItem] = useState(Flight2024list[0]?.id || "");

  const getLetterClass = (letter) => {
    const index = "FLIGHT".indexOf(letter.toUpperCase());
    if (index !== -1 && index < Flight2024list.length) {
      return Flight2024list[index].id === activeItem
        ? "flightinsights-acronym__letter--active"
        : "";
    }
    return "";
  };

  return (
    <>
      <UniversityCollaboration />
      <div className="flightinsights">
        {/* International university collaboration section */}

        <div className="flightinsights-wrapper">
          <div>
            <img
              src={Smalllogo}
              className="flightinsights-wrapper-img"
              alt="Small Logo"
            />

            <div className="flightinsights-acronym">
              {/* {"F.L.I.G.H.T.".split("").map((letter, index) => (
              <span
                key={index}
                className={`flightinsights-acronym__letter ${getLetterClass(
                  letter
                )}`}
              >
                {letter}
              </span>
            ))} */}
            </div>
            <div className="flightinsights-headline">
              Critical Elements of<br></br>Global Internship Program
            </div>
            <ImagesliderContainer />
          </div>
          <div className="internexperience-headline">
            Global Internship Program Experiencial Journey
          </div>
          <TestimonialSection />
          <div className="grid-background">
            <div className="whytm" style={{ backgroundColor: "transparent" }}>
              <div
                className="whytm__header"
                style={{ color: "rgb(1, 51, 51)", paddingTop: "4rem" }}
              >
                Your Experiencial Education Journey Starts with FLIGHT Mobility
                Program 2025
              </div>

              <Multiplepoints
                text1=""
                text2=""
                text3=""
                border="false"
                data={whytm}
                headcolor="rgb(28, 126, 116)"
                textalign="center"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flightinsights;
