import React, { useState } from "react";
import {
  MapPin,
  Clock,
  CalendarDays,
  Users,
  Download,
  CheckCircle2,
  GraduationCap,
} from "lucide-react";
import "../../sass/CourseOfferings.scss";
import Mccoyphoto from "../../Images/mccoyphoto.jpg";
import Unlvlogo from "../../Images/unlv_logo.jpeg";
import Mccoylogo from "../../Images/texasstatelogo.png";
import Mccoywholecampus from "../../Images/mccoywholecampus.jpeg";
import Unlvwholecampus from "../../Images/unlvwholecampus.jpg";

const CourseOfferings = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openBrochure = () => {
    window.open("https://docs.google.com/your-document-id/edit", "_blank");
  };

  const courses = [
    {
      name: "Business Entrepreneurship",
      shortName: "Business Entrepreneurship",
      university: "Texas State University",
      college: "McCoy College of Business, San Marcos",
      location: "San Marcos, Texas",
      duration: "6 weeks",
      months: "June - August",
      intake: "15 students",
      brochureUrl:
        "https://drive.google.com/file/d/1Em16Pufk8oWcXpZj6ASRdMajPltyF1_7/view?usp=sharing",
      logo: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/texasstatelogo.png?alt=media&token=81461ba9-6d4f-4b75-9a77-46e7c98b12f1",
      eligibility: [
        "Currently enrolled in any Bachelor's or Master's program",
        "Interdisciplinary branches from First year to Final year",
      ],
      features: [
        "Entrepreneurship Training",
        "Leadership Development",
        "Professional Sales",
        "Business Visits to Austin & San Antonio",
      ],
      image:
        "https://docs.gato.txst.edu/663161/w/2000/AF4s9BEfSio2/36087364320_08f3117160-landscape.jpg",
    },
    {
      name: "Technology Program",
      shortName: "Technology",
      university: "University of Nevada Las Vegas",
      college: "Howard R. Hughes College of Engineering",
      location: "Las Vegas, Nevada",
      duration: "8 weeks",
      months: "June - August",
      intake: "20 students",
      brochureUrl:
        "https://drive.google.com/file/d/1IXCXxws64R-NYQk7POPeQrXefJ9Bgs0R/view?usp=sharing",
      logo: "https://home.ecsi.net/-/media/project/gpn/heartland/ecsi/case-studies/unlv-reverse.jpg?h=334&iar=0&w=1140&rev=ecbcb26d262941c0957b27b5bcf92874",
      eligibility: [
        "Engineering/Technology background",
        "Interdisciplinary branches from First year to Final year",
      ],
      features: [
        "AI & Machine Learning",
        "Robotics Projects",
        "IoT Applications",
        "Industry Collaboration",
      ],
      image:
        "https://www.unlv.edu/sites/default/files/styles/1500_width/public/media/image/2022-07/our_campus-D73709_298-2000x1333.jpg?itok=pDg9zORX",
    },
    {
      name: "Design Program",
      shortName: "Design",
      university: "University of Nevada Las Vegas",
      college: "Howard R. Hughes College of Engineering",
      location: "Las Vegas, Nevada",
      duration: "8 weeks",
      months: "June - August",
      intake: "20 students",
      brochureUrl:
        "https://drive.google.com/file/d/133raGE6JpEIbANfpnpJ_-lrR8CqxTwra/view?usp=sharing",
      logo: "https://home.ecsi.net/-/media/project/gpn/heartland/ecsi/case-studies/unlv-reverse.jpg?h=334&iar=0&w=1140&rev=ecbcb26d262941c0957b27b5bcf92874",
      eligibility: [
        "Design or Engineering background",
        "Interdisciplinary branches from First year to Final year",
      ],
      features: [
        "UI/UX Design",
        "Project-based Learning",
        "Industry Visits",
        "Portfolio Development",
      ],
      image:
        "https://www.unlv.edu/sites/default/files/styles/1500_width/public/media/image/2022-07/our_campus-D73709_298-2000x1333.jpg?itok=pDg9zORX",
    },
  ];

  return (
    <div className="course-offerings">
      <div className="course-offerings__container">
        <h2 className="course-offerings__title">
          Global Internship Program 2025 Offerings
        </h2>
        <p className="course-offerings__subtitle">Guided Internship Programs</p>

        <div className="course-tabs">
          {courses.map((course, idx) => (
            <button
              key={idx}
              onClick={() => setActiveIndex(idx)}
              className={`course-tabs__button ${
                idx === activeIndex ? "active" : ""
              }`}
            >
              <span className="course-tabs__text">{course.shortName}</span>
            </button>
          ))}
        </div>

        <div className="course-content">
          {/* Image Section */}
          <div className="course-image">
            <img
              src={
                courses[activeIndex].name === "Business Entrepreneurship"
                  ? Mccoywholecampus
                  : Unlvwholecampus
              }
              alt={courses[activeIndex].name}
              className="course-image__img"
            />
            <div className="course-image__overlay">
              <div className="course-image__location">
                <MapPin className="course-image__icon" />
                <span>{courses[activeIndex].location}</span>
              </div>
              <h3 className="course-image__title">
                {courses[activeIndex].name}
              </h3>
              <p className="course-image__college">
                {courses[activeIndex].college}
              </p>
            </div>
          </div>

          {/* Details Section */}
          <div className="course-details">
            {/* College Logo and Name Section */}
            <div className="college-info">
              <img
                src={
                  courses[activeIndex].name === "Business Entrepreneurship"
                    ? Mccoylogo
                    : Unlvlogo
                }
                alt={courses[activeIndex].university}
                className="college-info__logo"
              />
              <h3 className="college-info__name">
                {courses[activeIndex].college}
              </h3>
            </div>

            {/* Program Stats */}
            <div className="program-stats">
              <div className="program-stats__item program-stats__item--duration">
                <Clock className="program-stats__icon" />
                <p className="program-stats__label">Duration</p>
                <p className="program-stats__value">
                  {courses[activeIndex].duration}
                </p>
              </div>
              <div className="program-stats__item program-stats__item--months">
                <CalendarDays className="program-stats__icon" />
                <p className="program-stats__label">Months</p>
                <p className="program-stats__value">
                  {courses[activeIndex].months}
                </p>
              </div>
              <div className="program-stats__item program-stats__item--intake">
                <Users className="program-stats__icon" />
                <p className="program-stats__label">Intake</p>
                <p className="program-stats__value">
                  {courses[activeIndex].intake}
                </p>
              </div>
            </div>

            {/* Eligibility Section */}
            <div className="eligibility">
              <h4 className="eligibility__title">Eligibility Criteria</h4>
              <div className="eligibility__grid">
                {courses[activeIndex].eligibility.map((item, idx) => (
                  <div key={idx} className="eligibility-item">
                    <CheckCircle2 className="eligibility-item__icon" />
                    <span className="eligibility-item__text">{item}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Features Section */}
            <div className="program-features">
              <h4 className="program-features__title">Program Highlights</h4>
              <div className="program-features__grid">
                {courses[activeIndex].features.map((feature, idx) => (
                  <div key={idx} className="feature-item">
                    <div className="feature-item__dot" />
                    <span className="feature-item__text">{feature}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="course-actions">
              <button
                className="course-actions__apply"
                onClick={() => scrollToSection("form-and-pass-container-outer")}
              >
                Apply Now
              </button>
              <button
                className="course-actions__download"
                onClick={() =>
                  window.open(courses[activeIndex].brochureUrl, "_blank")
                }
              >
                <Download className="course-actions__download-icon" />
                Download Program Document
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseOfferings;
