import React, { useRef, useState } from "react";
import { ArrowRight } from "lucide-react";
import Brochures from "../../Images/Brochure2025.png";
import ContactImage from "../../Images/contactimage.png";

const BrochureContact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    hearAboutUs: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const hearAboutUsOptions = [
    "Instagram",
    "LinkedIn",
    "Friends or Family",
    "College/University",
    "Online Search",
    "Advertisement",
    "Email",
    "Other",
  ];

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.phone.trim()) tempErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.phone))
      tempErrors.phone = "Invalid phone number";
    if (!formData.email.trim()) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Invalid email address";
    if (!formData.hearAboutUs)
      tempErrors.hearAboutUs = "This field is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("phoneNumber", formData.phone);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("message", formData.message);
        formDataToSend.append("hearAboutUs", formData.hearAboutUs);

        const response = await fetch(
          "https://us-central1-arcticturn1.cloudfunctions.net/submitForm?type=brochure",
          {
            method: "POST",
            body: formDataToSend,
          }
        );
        const data = await response.json();
        if (response.ok) {
          setSubmitMessage("Brochure request submitted successfully!");
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
            hearAboutUs: "",
          });
          setTimeout(() => {
            toggleCard();
            setSubmitMessage("");
          }, 2000);
        } else {
          setSubmitMessage(`Error: ${data.error}`);
        }
      } catch (error) {
        setSubmitMessage("An error occurred. Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  function getCardStyle(buttonElement) {
    if (!buttonElement) return {};
    const rect = buttonElement.getBoundingClientRect();
    const initialTop = rect.top + rect.height / 2;
    const initialLeft = rect.left + rect.width / 2;
    return {
      "--initial-top": `${initialTop}px`,
      "--initial-left": `${initialLeft}px`,
    };
  }

  return (
    <div className="brochure-contact">
      <div className="brochure-contact__section ">
        <div className="brochure-contact__image-container">
          <img
            src={Brochures}
            alt="Brochure cover"
            className="brochure-contact__image brochure-contact__image--cover"
          />
        </div>
        <div className="brochure-contact__textcontainer">
          <h2 className="brochure-contact__title">Download Brochure</h2>
          <p className="brochure-contact__description">
            Download a brochure for an in-depth look at Global Internship
            Program 2024
          </p>
          <button
            className="brochure-contact__button brochure-contact__button--primary"
            onClick={toggleCard}
          >
            Download Brochure <ArrowRight size={20} />
          </button>
          {isOpen && (
            <div className="overlay">
              <div
                className="floatingcard"
                style={{
                  ...getCardStyle(buttonRef.current),
                  maxWidth: "500px",
                  padding: "20px",
                }}
              >
                <button className="close-button" onClick={toggleCard}>
                  X
                </button>
                <div className="floatingcard-content">
                  <h2>Download Our Brochure</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Name *</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={errors.name ? "error" : ""}
                      />
                      {errors.name && (
                        <span className="error-message">{errors.name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number *</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={errors.phone ? "error" : ""}
                      />
                      {errors.phone && (
                        <span className="error-message">{errors.phone}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? "error" : ""}
                      />
                      {errors.email && (
                        <span className="error-message">{errors.email}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="hearAboutUs">
                        How did you hear about us? *
                      </label>
                      <select
                        id="hearAboutUs"
                        name="hearAboutUs"
                        value={formData.hearAboutUs}
                        onChange={handleChange}
                        className={errors.hearAboutUs ? "error" : ""}
                        style={{ padding: "10px", borderRadius: "0.5rem" }}
                      >
                        <option value="">Select an option</option>
                        {hearAboutUsOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {errors.hearAboutUs && (
                        <span className="error-message">
                          {errors.hearAboutUs}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message (Optional)</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Download Brochure"}
                    </button>
                  </form>
                  {submitMessage && (
                    <p className="submit-message">{submitMessage}</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="brochure-contact__section brochure-contact__section--contact">
        <div className="brochure-contact__image-container">
          <img
            src={ContactImage}
            alt="Contact team"
            className="brochure-contact__image brochure-contact__image--team"
          />
        </div>
        <div className="brochure-contact__textcontainer">
          <h2 className="brochure-contact__title">Contact Us</h2>
          <p className="brochure-contact__description">
            Our team of advisors are ready to answer your questions
          </p>
          <form
            action="mailto:info@arcticturn.in"
            method="post"
            encType="text/plain"
          >
            <button className="brochure-contact__button brochure-contact__button--secondary">
              Talk to Us <ArrowRight size={20} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BrochureContact;
