import React, { useState } from "react";
import { MapPin, Clock, CreditCard, GraduationCap } from "lucide-react";
import "../../sass/UniversityCollaboration.scss";
import Unlvlogo from "../../Images/unlv_logo.jpeg";
import Mccoylogo from "../../Images/texasstatelogo.png";
import Mccoywholecampus from "../../Images/mccoywholecampus.jpeg";
import Unlvwholecampus from "../../Images/unlvwholecampus.jpg";

const UniversityCollaboration = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const universities = [
    {
      name: "Texas State University",
      shortName: "Texas State University, San Marcos, USA",
      college: "McCoy College of Business, San Marcos",
      program: "Business Entreprenuership",
      duration: "6 weeks",
      months: "June - August",
      fee: "5,20,000/-",
      location: "San Marcos, Texas",
      logo: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/texasstatelogo.png?alt=media&token=81461ba9-6d4f-4b75-9a77-46e7c98b12f1",
      features: [
        "Entrepreneurship Training",
        "Leadership Development",
        "Professional Sales",
        "Business Visits to Austin & San Antonio",
      ],
      image:
        "https://docs.gato.txst.edu/663161/w/2000/AF4s9BEfSio2/36087364320_08f3117160-landscape.jpg",
    },
    {
      name: "University of Nevada Las Vegas",
      shortName: "University of Nevada Las Vegas, USA",
      college: "Howard R. Hughes College of Engineering",
      program: "Design & Technology ",
      duration: "8 weeks",
      months: "May - July & June - August",
      fee: "5,50,000/-",
      location: "Las Vegas, Nevada",
      logo: "https://home.ecsi.net/-/media/project/gpn/heartland/ecsi/case-studies/unlv-reverse.jpg?h=334&iar=0&w=1140&rev=ecbcb26d262941c0957b27b5bcf92874",
      features: [
        "UI/UX Design Focus",
        "Project-based Learning",
        "Industry Visits",
        "Professional Portfolio Development",
      ],
      image:
        "https://www.unlv.edu/sites/default/files/styles/1500_width/public/media/image/2022-07/our_campus-D73709_298-2000x1333.jpg?itok=pDg9zORX",
    },
  ];

  return (
    <div className="university-collab">
      <div className="university-collab__container">
        <h2 className="university-collab__title">
          International University Collaborations
        </h2>
        <p className="university-collab__subtitle">
          Partner with world-class institutions for global excellence
        </p>

        <div className="university-tabs">
          {universities.map((uni, idx) => (
            <button
              key={idx}
              onClick={() => setActiveIndex(idx)}
              className={`university-tabs__button ${
                idx === activeIndex ? "active" : ""
              }`}
            >
              <span className="university-tabs__text">{uni.shortName}</span>
            </button>
          ))}
        </div>

        <div className="university-content">
          {/* Image Section */}
          <div className="university-image">
            <img
              src={
                universities[activeIndex].name === "Texas State University"
                  ? Mccoywholecampus
                  : Unlvwholecampus
              }
              alt={universities[activeIndex].name}
              className="university-image__img"
            />
            <div className="university-image__overlay">
              <div className="university-image__location">
                <MapPin className="university-image__icon" />
                <span>{universities[activeIndex].location}</span>
              </div>
              <h3 className="university-image__title">
                {universities[activeIndex].name}
              </h3>
              <p className="university-image__college">
                {universities[activeIndex].college}
              </p>
            </div>
          </div>

          {/* Details Section */}
          <div className="university-details">
            {/* College Logo and Name Section */}
            <div className="college-info">
              <img
                src={
                  universities[activeIndex].name === "Texas State University"
                    ? Mccoylogo
                    : Unlvlogo
                }
                alt={universities[activeIndex].name}
                className="college-info__logo"
              />
              <h3 className="college-info__name">
                {universities[activeIndex].college}
              </h3>
            </div>
            <div className="program-stats">
              <div className="program-stats__item program-stats__item--program">
                <GraduationCap className="program-stats__icon" />
                <p className="program-stats__label">Program</p>
                <p className="program-stats__value">
                  {universities[activeIndex].program}
                </p>
              </div>
              <div className="program-stats__item program-stats__item--duration">
                <Clock className="program-stats__icon" />
                <p className="program-stats__label">Program Duration</p>
                <p className="program-stats__value">
                  {universities[activeIndex].duration}
                </p>
              </div>
              <div className="program-stats__item program-stats__item--fee">
                <CreditCard className="program-stats__icon" />
                <p className="program-stats__label">Months</p>
                <p className="program-stats__value">
                  {/* ₹{universities[activeIndex].fee} */}
                  {universities[activeIndex].months}
                </p>
              </div>
            </div>

            <div className="program-features">
              <h4 className="program-features__title">Program Highlights</h4>
              <div className="program-features__grid">
                {universities[activeIndex].features.map((feature, idx) => (
                  <div key={idx} className="feature-item">
                    <div className="feature-item__dot" />
                    <span className="feature-item__text">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Dots */}
        {/* <div className="university-nav">
          {universities.map((_, idx) => (
            <button
              key={idx}
              onClick={() => setActiveIndex(idx)}
              className={`university-nav__dot ${
                idx === activeIndex ? "active" : ""
              }`}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default UniversityCollaboration;
